<template>
  <div>
    <GmapMap
      v-if="$store.state.isGoogleApiLoaded"
      ref="map"
      :center="this.$store.getters['auth/companyLocation']"
      :zoom="13"
      style="width: 100%; height: calc(100vh - 50px)"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        {{infoContent}}
      </gmap-info-window>
      <template v-for="poly in polygonsGoogleList">
        <gmap-polygon
          v-if="visiblePolygonsIds.includes(poly.id)"
          v-bind:key="'polygon-' + poly.id"
          :paths="poly.paths"
          :editable="true"
          :draggable="false"
          @paths_changed="onPolygonPathsChanged($event, poly.id)"
          @click="showPolygonDesc($event, poly.name)" :options="{fillColor: fillC, fillOpacity: 0.5,strokeOpacity: 0.8,strokeWeight: 1}"
          ref="polygon"
        ></gmap-polygon>
      </template>
    </GmapMap>
    <div class="map-pol">
        <b-button class="pull-right" v-b-toggle.collapse-1 variant="primary"><b-icon icon="pencil-square"></b-icon> Editează zonele</b-button>
        <div class="clearfix"></div>
        <b-collapse id="collapse-1" class="mt-2">
            <b-card style="height: 400px; overflow-y: scroll;">
              <label style="padding-left: 20px;">
                <input @change="togglePolygonsVisibility($event.target.checked)" style="position: relative;top: 1px;" type="checkbox" class="mr-2" /> Show all
              </label>
              <hr class="m-0 p-0 mb-2" />
              <b-list-group v-if="polygonsRawList.length > 0">
                <b-list-group-item v-for="polygon in polygonsRawList" :key="'pli-' + polygon.id" class="d-flex">
                  <div class="mr-auto">
                    <input style="position: relative;top: 3px;" type="checkbox" v-model="visiblePolygonsIds" :value="polygon.id" />
                  </div>
                  <div class="mr-auto">{{ polygon.name }}</div>
                  <div>
                    <b-link class="mr-2" @click="editPolygon(polygon)"><b-icon icon="pencil-square"></b-icon> Modifică</b-link>
                    <b-link class="text-danger" @click="deletePolygon(polygon.id)"><b-icon icon="trash"></b-icon> Șterge</b-link>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div v-else>No zones</div>
            </b-card>
        </b-collapse>
    </div>
    <b-modal id="editModal">
      <template #modal-title>
        <div v-if="polygonInEdit">
          Modifică zona
        </div>
      </template>
      <b-form>
        <b-form-group
          v-if="polygonInEdit"
          label="Denumire"
          label-for="polygon-name"
        >
          <b-form-input
            id="polygon-name"
            v-model="polygonInEdit.name"
            type="text"
            placeholder="Nume zona"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel()">
          Renunță
        </b-button>
        <b-button variant="primary" @click="ok(); updatePolygon(polygonInEdit).then(() => { polygonInEdit = null });">
          Salvare
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import config from '@/config'
Vue.use(VuejsDialog)
export default {
  name: 'MapPolygons',
  data () {
    return {
      fillC: '#F7F7F7',
      polygons: [],
      polygonsRawList: [],
      updateTimeout: null,
      isGoogleApiLoaded: false,
      // InfoWindow
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoContent: '',
      infoWinOpen: false,
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      currentMidx: null,
      polygonInEdit: null,
      visiblePolygonsIds: []
    }
  },
  mounted () {
    const ids = localStorage.getItem('visiblePolygonsIds')
    if (ids) {
      this.visiblePolygonsIds = JSON.parse(ids)
    }
    if (this.$store.state.isGoogleApiLoaded) {
      this.initData()
    }
  },
  computed: {
    polygonsGoogleList () {
      return this.polygonsRawList.map((item) => {
        const itemNew = Object.assign({}, item)
        itemNew.paths = []
        item.paths.forEach((it) => {
          itemNew.paths.push(new window.google.maps.LatLng(it.lat, it.long))
        })
        return itemNew
      })
    }
  },
  methods: {
    initData () {
      this.getSavedPolygons()
        .then(() => {
          this.loadMapDrawingManager()
        })
    },
    savePolygon (data) {
      const len = data.polygon.getPath().getLength()
      const paths = []
      for (let i = 0; i < len; i++) {
        paths.push({
          lat: data.polygon.getPath().getAt(i).lat(),
          long: data.polygon.getPath().getAt(i).lng()
        })
      }
      console.log(paths)
      return Vue.axios.post(config.baseApiUrl + '/api/polygons', {
        name: data.name,
        paths: paths
      })
        .then((response) => {
          this.$toasted.success('Zone added', {
            duration: 3000
          })
          data.polygon.id = response.data.id
          this.polygons.push(data.polygon)
          this.getSavedPolygons()
          this.visiblePolygonsIds.push(data.polygon.id)
        })
    },
    updatePolygon (polygon) {
      return Vue.axios.patch(config.baseApiUrl + '/api/polygons/' + polygon.id, polygon)
        .then(() => {
          this.$toasted.success('Zone updated', {
            duration: 3000
          })
          this.getSavedPolygons()
        })
    },
    loadMapDrawingManager () {
      this.$refs.map.$mapPromise.then(() => {
        const drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              window.google.maps.drawing.OverlayType.POLYGON,
              window.google.maps.drawing.OverlayType.CIRCLE,
              window.google.maps.drawing.OverlayType.RECTANGLE
            ]
          },
          polygonOptions: {
            fillColor: '#0099FF',
            fillOpacity: 0.7,
            strokeColor: '#AA2143',
            strokeWeight: 1,
            editable: true
          }
        })
        drawingManager.setMap(this.$refs.map.$mapObject)

        window.google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
          return this.$dialog
            .prompt({
              title: 'Nume zona'
            })
            .then(dialog => {
              // Remove overlay from map
              polygon.setMap(null)
              // Disable drawingManager
              drawingManager.setDrawingMode(null)

              // Create Polygon
              return this.savePolygon({
                polygon: polygon,
                name: dialog.data
              })
            })
        })
      })
    },
    getSavedPolygons () {
      return Vue.axios.get(config.baseApiUrl + '/api/polygons')
        .then((response) => {
          this.polygonsRawList = response.data.data.map((polygon) => {
            polygon.isVisible = false
            return polygon
          })
        })
    },
    getPolygonById (id) {
      return this.polygonsRawList.find((item) => {
        return item.id === id
      })
    },
    onPolygonPathsChanged (mvcArray, polygonId) {
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout)
      }

      this.updateTimeout = setTimeout(() => {
        const paths = []
        for (let i = 0; i < mvcArray.getLength(); i++) {
          const path = []
          for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
            const point = mvcArray.getAt(i).getAt(j)
            path.push({ lat: point.lat(), long: point.lng() })
          }
          paths.push(path)
        }

        const polygon = this.getPolygonById(polygonId)
        polygon.paths = paths[0]

        this.axios({
          method: 'PATCH',
          url: config.baseApiUrl + '/api/polygons/' + polygonId,
          data: polygon
        })
          .then(() => {
            this.getSavedPolygons()
          })
      }, 300)
    },
    showPolygonDesc (event, name) {
      console.log(event)
      const contentString = name
      this.infoWindowPos = event.latLng
      this.infoContent = contentString
      this.infoWinOpen = true
    },
    editPolygon: function (polygon) {
      this.polygonInEdit = polygon
      this.$bvModal.show('editModal')
    },
    deletePolygon (polygonId) {
      this.$dialog.confirm('Remove this zone?')
        .then(() => {
          this.$axios.delete(config.baseApiUrl + '/api/polygons/' + polygonId)
            .then(() => {
              this.$toasted.success('Zone deleted', {
                duration: 3000
              })
              this.getSavedPolygons()
            })
        })
    },
    togglePolygonsVisibility (value) {
      if (value) {
        this.visiblePolygonsIds = this.polygonsRawList.map((polygon) => {
          return polygon.id
        })
      } else {
        this.visiblePolygonsIds = []
      }
    }
  },
  watch: {
    '$store.state.isGoogleApiLoaded': function (value) {
      if (value) {
        this.initData()
      }
    },
    visiblePolygonsIds: function (value) {
      localStorage.setItem('visiblePolygonsIds', JSON.stringify(value))
    }
  }
}
</script>

<style scoped>

</style>
