<template>
  <div class="container-fluid">
    <router-link to="/cars" class="btn btn-info">&lt; Inapoi la lista</router-link>
    <form v-if="car" @submit.prevent="save">
      <div class="mb-3">
        <label>Model</label>
        <input v-model="car.model" type="text" required class="form-control" />
      </div>
      <div class="mb-3">
        <label>Color</label>
        <input v-model="car.color" type="text" required class="form-control" />
      </div>
      <div class="mb-3">
        <label>Number</label>
        <input v-model="car.number" type="text" required class="form-control" />
      </div>
      <div class="mb-3">
        <label>Indicativ</label>
        <input v-model="car.indicativ" type="text" required class="form-control" />
      </div>
      <div class="mb-3">
        <label>Dispecerate</label>
        <CompanySelect v-model="car.companies" :multiple="true" :reduce="item => item"></CompanySelect>
      </div>
      <button type="submit" class="btn btn-success">SAVE</button>
    </form>
  </div>
</template>

<script>
import config from '@/config'
import CompanySelect from '@/components/common/CompanySelect'

export default {
  name: 'CarEdit',
  components: { CompanySelect },
  data () {
    return {
      car: {
        id: null,
        model: '',
        color: '',
        number: '',
        indicativ: '',
        companies: []
      }
    }
  },
  mounted () {
    if (this.$route.params.id !== '0') {
      this.$axios.get(config.baseApiUrl + '/api/cars/' + this.$route.params.id)
        .then((response) => {
          this.car = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    save () {
      this.$axios({
        method: this.$route.params.id === '0' ? 'post' : 'patch',
        url: config.baseApiUrl + '/api/cars' + (this.$route.params.id === '0' ? '' : ('/' + this.$route.params.id)),
        data: this.car
      })
        .then((response) => {
          this.$toasted.success('Saved', {
            duration: 3000
          })
          if (this.$route.params.id === '0') {
            this.car = response.data
            this.$router.push({ name: 'CarEdit', params: { id: response.data.id } })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
