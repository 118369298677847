<template>
  <div class="container-fluid">
    <CarsList :users-type="$route.params.type"></CarsList>
  </div>
</template>

<script>
import CarsList from '@/components/cars/CarsList'
export default {
  name: 'Cars',
  components: { CarsList }
}
</script>

<style scoped>

</style>
